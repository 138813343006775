export const loginPageLabels = {
  clbs: {
    0: "Leadership Behaviour Scale",
    1: "領導行為量表",
    2: "领导行为量表",
  },
  login: {
    0: "Login",
    1: "登入",
    2: "登入",
  },
  logout: {
    0: "Logout",
    1: "登出",
    2: "登出",
  },
  email: {
    0: "User ID / Email",
    1: "用戶編號 / 電郵地址",
    2: "用户编号 / 电邮地址",
  },
  password: {
    0: "Password",
    1: "登入密碼",
    2: "登入密码",
  },
  language: {
    0: "Language",
    1: "語言",
    2: "语言",
  },
  privacy: {
    0: "Your privacy is important to us. See our ",
    1: "你的隱私對我們很重要，請參閱我們的",
    2: "你的隐私对我们很重要，请参阅我们的",
  },
  policy: {
    0: "Privacy Policy.",
    1: "隱私政策。",
    2: "隐私政策。",
  },
  terms: [
    {
      0: "I have read and understand the ",
      1: "我已閱讀並明白本網站的",
      2: "我已阅读并明白本网站的",
    },
    {
      0: "of this website.",
      1: "",
      2: "",
    },
  ],
  conditions: {
    0: "Terms and Conditions ",
    1: "條款。",
    2: "条款。",
  },
};

export const assessmentLabels = {
  start: {
    0: "Start",
    1: "開始",
    2: "开始",
  },
  help: {
    0: "Help",
    1: "幫助",
    2: "帮助",
  },
  next: {
    0: "Next",
    1: "下一頁",
    2: "下一页",
  },
  back: {
    0: "Back",
    1: "上一頁",
    2: "上一页",
  },
  saveProgress: {
    0: "Save Progress",
    1: "儲存進度",
    2: "储存进度",
  },
  submit: {
    0: "Submit",
    1: "提交",
    2: "提交",
  },
  fillAll: {
    0: "Please fill out all questions",
    1: "請回答所有問題",
    2: "请回答所有问题",
  },
  unanswered: {
    0: "Unanswered Questions: ",
    1: "未回答的問題: ",
    2: "未回答的问题: ",
  },
  confirm: {
    0: "Confirm",
    1: "確認",
    2: "确认",
  },
  cancel: {
    0: "Cancel",
    1: "取消",
    2: "取消",
  },
  confirmSubmit: {
    0: " to submit your answers?",
    1: "提交你的答案嗎？",
    2: "提交你的答案吗？",
  },
  areYouSure: {
    0: "Are you sure you want ",
    1: "您確認要",
    2: "您确认要",
  },
  successfulSubmitted: {
    0: "You have successfully submitted your answers!",
    1: "你已成功提交答案！",
    2: "你已成功提交答案！",
  },
  failedSubmitted: {
    0: "Failed to submit answers, please try again!",
    1: "提交失敗，請嘗試再次提交答案！",
    2: "提交失败，请尝试再次提交答案！",
  },
  successfulSaved: {
    0: "You have successfully saved answer!",
    1: "你已成功保存進度！",
    2: "你已成功保存进度！",
  },
  failedSaved: {
    0: "Failed to save answer, please try again!",
    1: "保存失敗，請再次嘗試！",
    2: "保存失败，请再次尝试！",
  },
  notCompleted: {
    0: "Assessment Not Completed!",
    1: "尚未完成評估",
    2: "尚未完成评估",
  },
  endOfAssessment: {
    0: "End of Assessment",
    1: "完成問卷",
    2: "完成問卷",
  },
  endOfAssessmentPrompt: {
    0: "You have successfully submitted your answers! Thank You!",
    1: "你已成功提交答案。謝謝！",
    2: "你已成功提交答案。 谢谢！",
  },
};

export const appBarLabel = {
  assessment: {
    0: "Assessment",
    1: "評估",
    2: "评估",
  },
  contactUs: {
    0: "Contact us",
    1: "聯繫我們",
    2: "联系我们",
  },
  adminPanel: {
    0: "Admin Panel",
    1: "管理版面",
    2: "管理版面",
  },
};

export const logoutLabel = {
  confirmLogout: {
    0: "Are you sure to logout?",
    1: "你確定要登出嗎？",
    2: "你确定要登出吗？",
  },
  confirmLogoutPrompt: {
    0: "Press 'Save Progress' button before you log out of the system to save all completed responses. You can log in to complete the assessment using your Login Email and password.",
    1: "請在登出系統前按「儲存進度」 按鈕以儲存所有已完成的答案。你可以使用登入系統之電郵地址及密碼再次登入以完成評估。",
    2: "请在登出系统前按「储存进度」 按钮以储存所有已完成的答案。你可以使用登入系统之电邮地址及密码再次登入以完成评估。",
  },
};
