import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgressPopup from "../utils/circularProgress";

import ClbsAppBar from "../wrapper/appBar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { baseBackURL } from "../config/baseUrl";
import { UserContext } from "../context/userContext";
import { LanguageContext } from "../context/languageContext";
import SnackBarDuet from "../utils/snackBarDuet";
import ConfirmDialog from "../utils/confirmDialog";
import { Alert, AlertTitle } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import StepProgress from "../utils/stepProgress";
import { answerLabel } from "../config/answerLabel";
import { assessmentLabels } from "../config/commonLabels";
import ClbsDemographics from "./clbsDemographics";

const assessmentTitle = {
  0: "Leadership Behaviour Scale",
  1: "領導行為量表",
  2: "领导行为量表",
};

const questionFiles = {
  0: "/question_en.json",
  1: "/question_trad_ch.json",
  2: "/question_simp_ch.json",
};
const demographicLabels = {
  Gender: {
    0: "Gender",
    1: "性别",
    2: "性别",
  },
  Age: {
    0: "Age Group",
    1: "年齡組別",
    2: "年龄组别",
  },
  Profession: {
    0: "Profession/Occupation",
    1: "專業/職業",
    2: "专业/职业",
  },
  yearsManagementExperience: {
    0: "Years of Management Experience",
    1: "管理經驗的年資",
    2: "管理经验的年资",
  },
};
const questionPerPage = 10;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    minHeight: 600,
    margin: "20px",
    padding: "20px",
    maxWidth: "90%",
  },
  card: {
    minWidth: 275,
    maxWidth: "90%",
    margin: "auto",
    marginBlock: "20px",
    padding: "20px",
  },
  pageination: {
    justifyContent: "center",
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(2),
  },
  radioGroup: {
    width: "auto",
  },
}));

export default function Questionnaire() {
  const { tokenKey } = useContext(UserContext);
  const [token] = tokenKey;

  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const [page, setPage] = useState(() =>
    localStorage.getItem("currentPage") === null
      ? 1
      : parseInt(localStorage.getItem("currentPage"))
  );
  const [chunckedQuestions, setChunckedQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [fetchingUserAnser, setFetchingUserAnswer] = useState(false);

  const [openDialog, setOpenDialog] = useState({
    open: false,
    type: "",
    onConfirm: () => console.log("To be overriden"),
  });
  const [saveAnswerStatus, setSaveAnswerStatus] = useState({
    success: false,
    failed: false,
  });
  const [submitStatus, setSubmitStatus] = useState({
    success: false,
    failed: false,
  });
  const [emptyQuestions, setEmptyQuestions] = useState([]);
  const [unfinishedDialog, setUnfinishedDialog] = useState({
    open: false,
    questions: [],
  });

  const [isCompleted, setIsCompleted] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchUserFinished = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await fetch(
        baseBackURL + "/api/users/me",
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        setIsCompleted(data.clbs.is_completed === true);
      }
    };

    const fetchQuestions = async () => {
      const data = await fetch(questionFiles[language]);
      const dataJson = await data.json();
      let tmp = [];
      Object.keys(dataJson).forEach((key, idx) => {
        tmp.push({
          index: idx + 1,
          question: dataJson[key].question,
          reverse: dataJson[key].reverse,
        });
      });
      let tmpChunk = [];
      for (let i = 0; i < tmp.length; i += questionPerPage) {
        const chunk = tmp.slice(i, i + questionPerPage);
        tmpChunk.push(chunk);
      }
      setChunckedQuestions(tmpChunk);
    };

    const fetchUserAnswer = async () => {
      setFetchingUserAnswer(true);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await fetch(
        baseBackURL + "/api/assessment/clbs",
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        setUserAnswers(data);
      }
      setFetchingUserAnswer(false);
    };
    fetchUserFinished();
    fetchQuestions();
    fetchUserAnswer();
    const unloadHandler = (e) => {
      e.preventDefault();
      const message = "Are you sure you want to leave this page?";
      e.returnValue = message;
      return message;
    };
    window.addEventListener("beforeunload", unloadHandler);
    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, [token, language]);

  const handleNext = async () => {
    let hasUnanswered = false;
    let pageEmptyQuestion = [];
    for (let question of chunckedQuestions[page - 1]) {
      if (!(question.index in userAnswers)) {
        hasUnanswered = true;
        pageEmptyQuestion.push(question.index);
      }
    }
    if (hasUnanswered) {
      setUnfinishedDialog({
        open: true,
        questions: pageEmptyQuestion,
      });
    } else {
      localStorage.setItem("currentPage", page + 1);
      await handleSaveProgress();
      setPage((prevPage) => prevPage + 1);
      setUnfinishedDialog({
        open: false,
        questions: pageEmptyQuestion,
      });
    }
  };

  const handleBack = () => {
    localStorage.setItem("currentPage", page - 1);
    setPage((prevPage) => prevPage - 1);
  };

  const handleUpdateAnswer = (event, questionIdx) => {
    setUserAnswers({
      ...userAnswers,
      [questionIdx]: event.target.value,
    });
  };

  const handleSaveProgress = async () => {
    const data = JSON.stringify(userAnswers);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: data,
    };
    const response = await fetch(
      baseBackURL + "/api/assessment/clbs/save",
      requestOptions
    );
    if (!response.ok) {
      setSaveAnswerStatus({ success: false, failed: true });
    } else {
      setSaveAnswerStatus({ success: true, failed: false });
    }
  };

  const handleSubmit = async () => {
    setFetching(true);
    const data = JSON.stringify(userAnswers);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: data,
    };
    const response = await fetch(
      baseBackURL + "/api/assessment/clbs",
      requestOptions
    );
    setOpenDialog({ ...openDialog, open: false });
    setFetching(false);
    if (!response.ok) {
      setSubmitStatus({ success: false, failed: true });
    } else {
      setSubmitStatus({ success: true, failed: false });
    }
  };

  const checkAndSubmit = () => {
    let hasUnanswered = false;
    setEmptyQuestions([]);
    for (let questionChunk of chunckedQuestions) {
      for (let question of questionChunk) {
        if (!(question.index in userAnswers)) {
          hasUnanswered = true;
          setEmptyQuestions((emptyQuestions) => [
            ...emptyQuestions,
            question.index,
          ]);
        }
      }
    }
    for (let demographic of [
      "Gender",
      "Age",
      "Profession",
      "yearsManagementExperience",
    ]) {
      if (!(demographic in userAnswers)) {
        hasUnanswered = true;
        setEmptyQuestions((emptyQuestions) => [
          ...emptyQuestions,
          demographicLabels[demographic][language],
        ]);
      }
    }
    if (hasUnanswered) {
      setSubmitStatus({ success: false, failed: true });
    } else {
      setOpenDialog({
        open: true,
        type: assessmentLabels.confirmSubmit[language],
        onConfirm: handleSubmit,
      });
    }
  };

  return (
    <ClbsAppBar>
      {fetchingUserAnser && <CircularProgressPopup />}
      {isCompleted ? (
        <CompletedAssessment />
      ) : (
        <Card className={classes.root}>
          <CardContent>
            {chunckedQuestions.map(
              (chunk, index) =>
                page === index + 1 && (
                  <div key={index}>
                    <Typography variant="h4" color="textPrimary">
                      {assessmentTitle[language]}
                    </Typography>

                    {chunk.map((questionDetails, idx) => (
                      <div key={idx}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ marginBlock: "5px" }}
                          >
                            <Grid container direction="row" alignItems="center">
                              <Grid item>
                                <Typography variant="h6" color="textSecondary">
                                  {questionDetails.question}
                                </Typography>
                              </Grid>
                              {questionDetails.index in userAnswers && (
                                <Grid item>
                                  <CheckCircleOutlineIcon color="success" />
                                </Grid>
                              )}
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <RadioGroup
                              aria-label="score"
                              name="score"
                              value={
                                questionDetails.index in userAnswers
                                  ? userAnswers[questionDetails.index]
                                  : "null"
                              }
                              onChange={(event) =>
                                handleUpdateAnswer(event, questionDetails.index)
                              }
                              className={classes.radioGroup}
                            >
                              <Hidden xlDown>
                                <FormControlLabel
                                  value="null"
                                  control={<Radio />}
                                  label="null"
                                />
                              </Hidden>
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label={answerLabel[language]["1"]}
                                labelPlacement="right"
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label={answerLabel[language]["2"]}
                                labelPlacement="right"
                              />
                              <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label={answerLabel[language]["3"]}
                                labelPlacement="right"
                              />
                              <FormControlLabel
                                value="4"
                                control={<Radio />}
                                label={answerLabel[language]["4"]}
                                labelPlacement="right"
                              />
                              <FormControlLabel
                                value="5"
                                control={<Radio />}
                                label={answerLabel[language]["5"]}
                                labelPlacement="right"
                              />
                              <FormControlLabel
                                value="6"
                                control={<Radio />}
                                label={answerLabel[language]["6"]}
                                labelPlacement="right"
                              />
                              <FormControlLabel
                                value="7"
                                control={<Radio />}
                                label={answerLabel[language]["7"]}
                                labelPlacement="right"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label={answerLabel[language]["0"]}
                                labelPlacement="right"
                              />
                            </RadioGroup>
                          </AccordionDetails>
                        </Accordion>
                        <Divider />
                      </div>
                    ))}
                  </div>
                )
            )}
            {page === chunckedQuestions.length + 1 && (
              <ClbsDemographics
                language={language}
                onUpdateAnswer={handleUpdateAnswer}
              />
            )}
            <Grid container justifyContent="center">
              <StepProgress
                numSteps={chunckedQuestions.length + 1}
                activeStep={page - 1}
                handleNext={handleNext}
                handleBack={handleBack}
              />
            </Grid>
            <Divider />
            {emptyQuestions.length >= 1 && (
              <Alert severity="warning" style={{ margin: "10px" }}>
                <AlertTitle>
                  {assessmentLabels.notCompleted[language]}
                </AlertTitle>
                <strong>{assessmentLabels.unanswered[language]}</strong>{" "}
                {emptyQuestions.map((questionIdx, index) => (
                  <span key={index}>{questionIdx}, </span>
                ))}
              </Alert>
            )}
            <Grid
              container
              justifyContent="flex-end"
              style={{ margin: "10px" }}
            >
              {page !== chunckedQuestions.length + 1 && (
                <Button
                  variant="outlined"
                  onClick={handleSaveProgress}
                  style={{ margin: "0.5em" }}
                >
                  {assessmentLabels.saveProgress[language]}
                </Button>
              )}
              <Button
                variant="outlined"
                color="primary"
                onClick={checkAndSubmit}
                style={{ margin: "0.5em" }}
              >
                {assessmentLabels.submit[language]}
              </Button>
            </Grid>
          </CardContent>
          <Dialog
            open={submitStatus.success}
            onClose={() => setSubmitStatus({ success: false, failed: false })}
          >
            <DialogTitle>
              {assessmentLabels.endOfAssessment[language]}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {assessmentLabels.endOfAssessmentPrompt[language]}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  setSubmitStatus({ success: false, failed: false })
                }
              >
                {assessmentLabels.confirm[language]}
              </Button>
            </DialogActions>
          </Dialog>
          <ConfirmDialog
            open={openDialog.open}
            onCloseDialog={() => setOpenDialog({ ...openDialog, open: false })}
            title={assessmentLabels.confirm[language] + openDialog.type}
            details={assessmentLabels.areYouSure[language] + openDialog.type}
            onConfirm={openDialog.onConfirm}
            isFetching={fetching}
          />
          <ConfirmDialog
            open={unfinishedDialog.open}
            onCloseDialog={() =>
              setUnfinishedDialog({ ...unfinishedDialog, open: false })
            }
            title={assessmentLabels.fillAll[language]}
            details={
              assessmentLabels.unanswered[language] + unfinishedDialog.questions
            }
            onConfirm={() =>
              setUnfinishedDialog({ ...unfinishedDialog, open: false })
            }
            isFetching={false}
          />
          <SnackBarDuet
            status={saveAnswerStatus}
            closeSuccess={() =>
              setSaveAnswerStatus({ success: false, failed: false })
            }
            closeFailed={() =>
              setSaveAnswerStatus({ success: false, failed: false })
            }
            successPrompt={assessmentLabels.successfulSaved[language]}
            failedPrompt={assessmentLabels.failedSaved[language]}
          />
          <SnackBarDuet
            status={submitStatus}
            closeSuccess={() =>
              setSubmitStatus({ success: false, failed: false })
            }
            closeFailed={() =>
              setSubmitStatus({ success: false, failed: false })
            }
            successPrompt={assessmentLabels.successfulSubmitted[language]}
            failedPrompt={assessmentLabels.failedSubmitted[language]}
          />
        </Card>
      )}
    </ClbsAppBar>
  );
}

const CompletedAssessment = () => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          color="textPrimary"
          variant="h4"
          style={{ textAlign: "center" }}
        >
          {assessmentLabels.endOfAssessment[language]}
        </Typography>
        <Typography style={{ textAlign: "center" }} color="textSecondary">
          {assessmentLabels.endOfAssessmentPrompt[language]}
        </Typography>
      </CardContent>
    </Card>
  );
};
